<template>
	<v-container fill-height fluid>
		<v-row>
			<v-col cols="12" md="6" offset-md="3">
				<v-card height="100%">
					<v-card-text>
						<div class="text-center py-5">
							<img
								:src="require('@/assets/california.png')"
								width="15%"
								alt=""
							/>
						</div>
					</v-card-text>
					<v-form @submit.prevent="$store.dispatch('Auth/login', user)">
						<v-card-text>
							<v-text-field
								outlined
								dense
								autocomplete="email"
								label="Correo"
								prepend-icon="mdi-email"
								type="email"
								id="email"
								v-model="user.email"
								:error-messages="errors.email"
							/>
							<v-text-field
								outlined
								dense
								autocomplete="current-password"
								id="password"
								label="Contraseña"
								prepend-icon="mdi-lock"
								type="password"
								v-model="user.password"
								:error-messages="errors.password"
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn color="primary" type="submit" :disabled="loader"
								>Ingresar
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Login',
	data: () => ({
		user: {}
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			loader: state => state.Auth.loader
		})
	},
	mounted() {
		this.$store.commit('setErrors');
	}
};
</script>
