<template>
	<v-card height="100%">
		<v-card-text>
			<v-form
				@submit.prevent="$store.dispatch('Compras/getAll')"
				:disabled="loader"
			>
				<v-row>
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar por PU o PO"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="info" type="submit">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1"
				:loading="loader"
				:items-per-page="paginado.perPage"
				hide-default-footer
			>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.fecha`]="{ item }">
					{{ item.fecha | date }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions :item="item" :loader="loader" @details="details" />
				</template>
			</v-data-table>
			<Paginado :loader="loader" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	mounted() {
		this.$store.dispatch('Compras/getAll');
	},
	computed: {
		...mapState({
			loader: state => state.Compras.loader,
			headers: state => state.Compras.headers,
			items: state => state.Compras.collection,
			paginado: state => state.Compras.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		search() {
			return this.paginado.search;
		}
	},
	methods: {
		details(item) {
			this.$store.dispatch('Compras/details', item.id);
		}
	},
	watch: {
		actual: function () {
			this.$store.dispatch('Compras/getAll');
		},
		search: function (val) {
			if (!val) this.$store.dispatch('Compras/getAll');
		}
	}
};
</script>
