<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Producto
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-2">
				<v-col cols="12" md="6">
					<v-text-field
						dense
						outlined
						clearable
						label="Buscar"
						v-model="search"
					/>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="details"
				class="elevation-1"
				:items-per-page="perPage"
				sortBy="producto"
				:search.sync="search"
			>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Products',
	data: () => ({
		headers: [
			{ text: 'Pallet ID', value: 'pallet_id' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Tipo de Caja', value: 'tipo_caja' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio Compra', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Vendidos', value: 'vendidos', align: 'end' },
			{ text: 'Apartados', value: 'apartados', align: 'end' },
			{ text: 'Disponibles', value: 'disponibles', align: 'end' }
		],
		perPage: 15,
		search: undefined
	}),
	computed: {
		...mapState({
			loader: state => state.Compras.loader,
			resource: state => state.Compras.resource,
			details: state => state.Compras.details
		})
	}
};
</script>
