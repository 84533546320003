<template>
	<v-card height="100%">
		<v-card-text>
			<h3>Busqueda de Códigos Postales</h3>
			<v-row class="mt-2">
				<v-col cols="12" md="6" xl="4">
					<v-select
						dense
						outlined
						clearable
						label="Tipo de Busqueda"
						:items="tipos"
						v-model="tipo"
					/>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-text v-if="tipo == 1">
			<v-row>
				<v-col>
					<v-autocomplete
						dense
						outlined
						clearable
						label="Seleccione Estado"
						:items="estados"
						v-model="estado"
					/>
				</v-col>
				<v-col>
					<v-autocomplete
						dense
						outlined
						clearable
						label="Seleccione Municipio"
						:items="municipios"
						v-model="municipio"
						item-text="name"
						item-value="id"
					/>
				</v-col>
				<v-col>
					<v-btn
						color="primary"
						:disabled="!municipio"
						@click="resultadosMunicipio"
						>buscar</v-btn
					>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-text v-if="resultados">
			<v-data-table
				dense
				:headers="headers"
				:items="resultados"
				class="elevation-1"
			>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Pruebas',
	data: () => ({
		estados: undefined,
		resultados: undefined,
		municipios: undefined,
		tipos: [
			{ value: 1, text: 'Por Estado/Municipio' },
			{ value: 2, text: 'Por Código Postal' },
			{ value: 3, text: 'Por Nombre de colonia' }
		],
		estado: undefined,
		municipio: undefined,
		tipo: undefined,
		cp: undefined,
		search: undefined,
		urlBase: 'https://codigos-postales.zamgod.mx/api',
		headers: [
			{ text: 'CP', value: 'cp' },
			{ text: 'Nombre', value: 'name' },
			{ text: 'Tipo de Asentamiento', value: 'type' },
			{ text: 'Estado', value: 'estado' },
			{ text: 'Municipio', value: 'municipio' }
		]
	}),
	mounted() {
		fetch(`${this.urlBase}/estados`)
			.then(response => response.json())
			.then(data => (this.estados = data));
	},
	methods: {
		metodoPost() {
			const data = new FormData();
			data.append('cp', '58337');
			fetch('https://codigos-postales.zamgod.mx/api/codigoPostal', {
				method: 'POST',
				body: data
			})
				.then(response => response.json())
				.then(data => (this.resultados = data));
		},
		getMunicipios() {
			fetch(`${this.urlBase}/municipios/${this.estado}`)
				.then(response => response.json())
				.then(data => (this.municipios = data));
		},
		resultadosMunicipio() {
			this.resultados = undefined;
			fetch(`${this.urlBase}/codigos/${this.municipio}`)
				.then(response => response.json())
				.then(data => (this.resultados = data));
		}
	},
	watch: {
		estado: function (val) {
			this.municipios = undefined;
			this.municipio = undefined;
			if (val) this.getMunicipios();
		},
		tipo: function () {
			this.estado =
				this.municipios =
				this.municipio =
				this.resultados =
					undefined;
		}
	}
};
</script>
