<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos del Cliente {{ original.name }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form
				@submit.prevent="$store.dispatch('Customers/sendResource')"
				:disabled="loader"
			>
				<v-card :loading="loader" class="mt-3">
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Nombre"
									v-model="resource.name"
									:error-messages="errors.name"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Correo"
									v-model="resource.email"
									:error-messages="errors.email"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Teléfono"
									v-model="resource.phone"
									:error-messages="errors.phone"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Contacto"
									v-model="resource.agent"
									:error-messages="errors.agent"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions v-if="modificado">
						<v-btn color="success" type="submit">actualizar</v-btn>
						<v-btn color="error" @click="cancelar">cancelar</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Customer',
	data: () => ({
		original: {}
	}),
	computed: {
		...mapState({
			resource: state => state.Customers.resource,
			loader: state => state.Customers.loader,
			errors: state => state.errors
		}),
		text() {
			if (!this.resource) return '';
			return this.resource.id ? 'actualizar' : 'guardar';
		},
		modificado() {
			return JSON.stringify(this.original) !== JSON.stringify(this.resource);
		}
	},
	mounted() {
		this.$store.commit('setErrors');
		if (!this.resource) {
			this.$store.dispatch('Customers/editar', this.$route.params.id);
		} else {
			this.original = Object.assign({}, this.resource);
		}
	},
	watch: {
		resource: function (val) {
			this.original = Object.assign({}, val);
		}
	},
	methods: {
		cancelar() {
			this.$store.commit('setErrors');
			this.$store.commit('Customers/setResource', this.original);
		}
	}
};
</script>
