var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.$store.dispatch('Sales/getAll')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Buscar PO"},model:{value:(_vm.paginado.search),callback:function ($$v) {_vm.$set(_vm.paginado, "search", $$v)},expression:"paginado.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.customers,"item-text":"name","item-value":"id"},model:{value:(_vm.paginado.customer_id),callback:function ($$v) {_vm.$set(_vm.paginado, "customer_id", $$v)},expression:"paginado.customer_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","item-class":_vm.rowClasses,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loader,"items-per-page":_vm.paginado.perPage},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item,"loader":_vm.loader},on:{"details":_vm.details}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.cajas))+" ")]}},{key:"item.charges",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.charges))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total))+" ")]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }