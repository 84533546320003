<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Producto
		</v-expansion-panel-header>
		<Formulario v-if="nuevo" @cancelar="cancelar" />
		<v-expansion-panel-content v-else>
			<v-row class="my-2">
				<v-col>
					<v-btn
						small
						color="success"
						@click="nuevo = true"
						:disabled="loader"
						v-if="userCan('budgets-edit') && resource.editable"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
					<strong class="ml-4">Cajas {{ cajas | numero }}</strong>
					<strong class="ml-4">
						Importe Total {{ importeTotal | moneda }}
					</strong>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@eliminar="eliminar"
						v-if="resource.editable"
					/>
				</template>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
			</v-data-table>
			<v-card-actions v-if="items.length">
				<v-btn
					color="primary"
					v-if="!resource.pickup"
					@click="$store.dispatch('Budgets/addPu')"
				>
					crear PU
				</v-btn>
				<v-btn color="primary" @click="venta" v-if="puedeCerrar">
					crear venta
				</v-btn>
			</v-card-actions>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Formulario from './AddProductForm.vue';
export default {
	name: 'Products',
	components: { Formulario },
	computed: {
		...mapState({
			loader: state => state.Budgets.loader,
			items: state => state.Budgets.products,
			resource: state => state.Budgets.resource,
			pu: state => state.Budgets.pu
		}),
		cajas() {
			return window._.sumBy(this.items, 'cantidad');
		},
		importeTotal() {
			if (!this.items.length) return 0;
			return this.items.reduce(function (accumulator, item) {
				let cantidad = parseFloat(item.cantidad),
					precio = parseFloat(item.precio);
				return accumulator + precio * cantidad;
			}, 0);
		},
		puedeCerrar() {
			let valor = true;
			if (!this.userCan('sales-create')) valor = false;
			if (!this.resource.pickup) valor = false;
			if (!this.puOriginal.warehouse_id) valor = false;
			if (!this.puOriginal.warehouse_address_id) valor = false;
			if (!this.puOriginal.destination_address_id) valor = false;
			if (!this.resource.editable) valor = false;
			return valor;
		}
	},
	data: () => ({
		nuevo: false,
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Pallet ID', value: 'pallet_id' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		puOriginal: {}
	}),
	methods: {
		cancelar() {
			this.nuevo = false;
		},
		eliminar(item) {
			this.$swal({
				title: `¿Eliminar el pallet ${item.pallet_id}?`,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Budgets/deleteDetail', item.id);
				}
			});
		},
		venta() {
			this.$swal({
				title: `¿Hacer la venta del Presupuesto ${this.resource.folio}?`,
				text: 'Una vez hecha la venta no se pueden agregar o quitar productos o modificar el PU',
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Budgets/createSale');
				}
			});
		}
	},
	mounted() {
		this.puOriginal = Object.assign({}, this.pu);
	},
	watch: {
		pu: function (val) {
			this.puOriginal = Object.assign({}, val);
		}
	}
};
</script>
