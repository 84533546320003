<template>
	<v-card height="100%" v-if="user">
		<Dashboard />
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import Dashboard from './dashboard/NewDashboard.vue';
export default {
	components: { Dashboard },
	name: 'Home',
	computed: {
		...mapState({
			user: state => state.Auth.user,
			loader: state => state.Auth.loader
		})
	}
};
</script>
