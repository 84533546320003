<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos Presupuesto {{ resource.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content v-if="resource.editable">
			<v-card class="mt-3" :loading="loader">
				<v-form
					@submit.prevent="$store.dispatch('Budgets/updateResource')"
					:disabled="loader"
				>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" xl="2">
								<v-text-field
									dense
									outlined
									clearable
									label="Fecha"
									type="date"
									v-model="resource.date"
									:error-messages="errors.date"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="5">
								<v-autocomplete
									dense
									outlined
									clearable
									label="Cliente"
									:items="customers"
									item-value="id"
									item-text="name"
									v-model="resource.customer_id"
									:error-messages="errors.customer_id"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="6" xl="5">
								<v-combobox
									dense
									outlined
									clearable
									label="Comprador"
									:items="buyers"
									v-model="resource.buyer"
									:error-messages="errors.buyer"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="2">
								<v-text-field
									dense
									outlined
									clearable
									label="PO (Orden de Compra)"
									v-model="resource.po"
									:error-messages="errors.po"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-textarea
									outlined
									label="Observaciones"
									v-model="resource.observations"
									:error-messages="errors.observations"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions v-if="modificado">
						<v-btn color="success" type="submit" :disabled="loader">
							actualizar
						</v-btn>
						<v-btn color="error" @click="cancelar">cancelar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-expansion-panel-content>
		<v-expansion-panel-content v-else>
			<v-card class="mt-3">
				<v-form disabled>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" xl="2">
								<v-text-field
									dense
									outlined
									clearable
									label="Fecha"
									type="date"
									v-model="resource.date"
									:error-messages="errors.date"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="5">
								<v-autocomplete
									dense
									outlined
									clearable
									label="Cliente"
									:items="customers"
									item-value="id"
									item-text="name"
									v-model="resource.customer_id"
									:error-messages="errors.customer_id"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="5">
								<v-autocomplete
									dense
									outlined
									clearable
									label="Domicilio de envío"
									:items="addresses"
									item-value="id"
									item-text="name"
									v-model="resource.address_id"
									:error-messages="errors.address_id"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="6">
								<v-combobox
									dense
									outlined
									clearable
									label="Comprador"
									:items="buyers"
									v-model="resource.buyer"
									:error-messages="errors.buyer"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="PO (Orden de Compra)"
									v-model="resource.po"
									:error-messages="errors.po"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-textarea
									outlined
									label="Observaciones"
									v-model="resource.observations"
									:error-messages="errors.observations"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-form>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Budget',
	data: () => ({
		original: undefined
	}),
	computed: {
		...mapState({
			customers: state => state.Budgets.customers,
			resource: state => state.Budgets.resource,
			loader: state => state.Budgets.loader,
			errors: state => state.errors
		}),
		modificado() {
			return JSON.stringify(this.original) !== JSON.stringify(this.resource);
		},
		customer() {
			if (!this.customers) return {};
			return this.customers.find(x => x.id == this.resource.customer_id);
		},
		addresses() {
			return this.customer ? this.customer.addresses : [];
		},
		buyers() {
			return this.customer ? this.customer.buyers : [];
		}
	},
	mounted() {
		if (this.resource.id) this.original = Object.assign({}, this.resource);
	},
	watch: {
		resource: function (val) {
			this.original = Object.assign({}, val);
		}
	},
	methods: {
		cancelar() {
			this.$store.commit('Budgets/setResource', this.original);
			this.$store.commit('setErrors');
		}
	}
};
</script>
