<template>
	<v-expansion-panel-content>
		<v-card class="mt-3" :loading="loader">
			<v-form :disabled="loader" @submit.prevent="enviar">
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6">
							<v-autocomplete
								dense
								outlined
								clearable
								label="Filtrar por Producto"
								:items="productos"
								v-model="product"
							/>
						</v-col>
						<v-col>
							<v-btn
								:color="btnColor"
								class="boton"
								@click="selected = !selected"
								:disabled="loader"
							>
								<v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-row class="my-2">
						<v-col>
							<strong>Cajas Seleccionadas {{ cajas | numero }}</strong>
							<strong class="ml-4">
								Importe Total {{ importeTotal | moneda }}
							</strong>
						</v-col>
					</v-row>
					<v-data-table
						dense
						hide-default-footer
						mobile-breakpoint="0"
						:headers="headers"
						:items="filtrados"
						:items-per-page="perPage"
						class="elevation-1 mt-3"
					>
						<template v-slot:[`item.precio_compra`]="{ item }">
							{{ item.precio_compra | moneda }}
						</template>
						<template v-slot:[`item.apartar`]="{ item }">
							<v-text-field
								type="number"
								dense
								outlined
								:max="item.disponibles"
								v-model.number="item.apartar"
								hide-details="auto"
							/>
						</template>
						<template v-slot:[`item.precio_venta`]="{ item }">
							<v-text-field
								type="number"
								step="0.01"
								dense
								outlined
								v-model.number="item.precio_venta"
								hide-details="auto"
							/>
						</template>
						<template v-slot:[`item.fecha_corte`]="{ item }">
							{{ item.fecha_corte | date }}
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="success"
						type="submit"
						:disabled="loader || !listos.length"
					>
						guardar
					</v-btn>
					<v-btn color="error" @click="cancelar">cancelar</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-expansion-panel-content>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Formulario',
	data: () => ({
		categories: [
			{ value: 'CAT 1', text: 'Categoría 1' },
			{ value: 'CAT 2', text: 'Categoría 2' }
		],
		types: [
			{ value: 'CONVENTIONAL', text: 'Convencional' },
			{ value: 'ORGANIC', text: 'Orgánico' }
		],
		selected: false,
		caliber: undefined,
		category: undefined,
		type: undefined,
		headers: [
			{ text: 'Producto', value: 'producto' },
			{
				text: 'Pallet ID',
				value: 'pallet_id',
				width: '100',
				align: '.d-sm-none .d-md-flex'
			},
			{
				text: 'Fecha de Corte',
				value: 'fecha_corte',
				width: '120'
			},
			{
				text: 'Precio Compra',
				value: 'precio_compra',
				align: 'end',
				width: '150'
			},
			{ text: 'Disponibles', value: 'disponibles', align: 'end', width: '150' },
			{ text: 'Apartar', value: 'apartar', align: 'end', width: '150' },
			{
				text: 'Precio Venta',
				value: 'precio_venta',
				align: 'end',
				width: '150'
			}
		],
		product: undefined
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			inventario: state => state.Budgets.inventario,
			detail: state => state.Budgets.detail,
			loader: state => state.Budgets.loader,
			budget_id: state => state.Budgets.resource.id
		}),
		perPage() {
			return this.filtrados.length;
		},
		calibres() {
			return [
				...new Set(this.inventario.map(x => x.producto.substring(0, 2)))
			].sort();
		},
		filtrados() {
			let data = this.inventario;
			if (this.selected) {
				data = this.listos;
			}
			if (this.caliber) {
				data = data.filter(x => x.producto.includes(this.caliber));
			}
			if (this.category) {
				data = data.filter(x => x.producto.includes(this.category));
			}
			if (this.product) {
				data = data.filter(x => x.producto.includes(this.product));
			}
			//if (!this.type && !this.caliber && !this.category) return [];
			return data;
		},
		btnColor() {
			return this.selected ? 'success' : 'warning';
		},
		listos() {
			return this.inventario.filter(
				x => x.apartar && x.disponibles >= x.apartar && x.precio_venta
			);
		},
		cajas() {
			return window._.sumBy(this.listos, 'apartar');
		},
		importeTotal() {
			if (!this.listos.length) return 0;
			return this.listos.reduce(function (accumulator, item) {
				let cantidad = parseFloat(item.apartar),
					precio = parseFloat(item.precio_venta);
				return accumulator + precio * cantidad;
			}, 0);
		},
		mandar() {
			let reformattedArray = this.listos.map(function (item) {
				return (({ id, apartar, precio_venta, producto, pallet_id }) => ({
					purchase_detail_id: id,
					cantidad: apartar,
					precio: precio_venta,
					producto,
					pallet_id
				}))(item);
			});
			reformattedArray.forEach(x => (x.budget_id = this.budget_id));
			return reformattedArray;
		},
		productos() {
			return [...new Set(this.inventario.map(x => x.producto))].sort();
		}
	},
	beforeMount() {
		this.$store.commit('Budgets/setDetail');
		this.$store.dispatch('Budgets/detailsResource', this.$route.params.id);
	},
	methods: {
		cancelar() {
			this.$emit('cancelar');
		},
		enviar() {
			this.$store.commit('Budgets/setLoader');
			window.axios
				.post('sucursal/budgets/addDetails', { items: this.mandar })
				.then(resp => {
					this.$store.dispatch('message', resp.data.message);
					this.$store.dispatch('Budgets/detailsResource', this.budget_id);
					this.cancelar();
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => {
					this.$store.commit('Budgets/setLoader', false);
				});
		}
	},
	watch: {
		selected: function () {
			this.caliber = this.category = this.type = undefined;
		}
	}
};
</script>
<style lang="scss" escoped>
.v-input__control {
	padding: 4px 0px;
}
input[type='number'] {
	text-align: right;
	margin-left: 20px !important;
}
.boton {
	margin-top: 4px !important;
}
</style>
