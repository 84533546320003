<template>
	<v-card height="100%">
		<v-card-text>
			<v-form
				@submit.prevent="$store.dispatch('Warehouses/getAll')"
				:disabled="loader"
			>
				<v-row class="mt-2">
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="info" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn
							color="success"
							class="ml-2"
							:disabled="loader"
							v-if="userCan('warehouse-create')"
							@click="$store.dispatch('Warehouses/nuevo')"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1"
				:loading="loader"
				:items-per-page="paginado.perPage"
				hide-default-footer
				:item-class="rowClasses"
			>
				<template v-slot:[`item.phone`]="{ item }">
					{{ item.phone | phone }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@editar="editar"
						@eliminar="eliminar"
						@restore="restore"
					/>
				</template>
			</v-data-table>
			<Paginado :loader="loader" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	mounted() {
		this.$store.dispatch('Warehouses/getAll');
	},
	computed: {
		...mapState({
			loader: state => state.Warehouses.loader,
			headers: state => state.Warehouses.headers,
			items: state => state.Warehouses.collection,
			paginado: state => state.Warehouses.paginado
		})
	},
	methods: {
		editar(item) {
			this.$store.dispatch('Warehouses/editar', item.id);
		},
		eliminar(item) {
			let msg = `¿Deshabilitar el almacén ${item.name}?`;
			this.$swal({
				title: msg,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Warehouses/disableResource', item.id);
				}
			});
		},
		restore(item) {
			this.$swal({
				title: `¿Habilitar el almacén ${item.name}?`,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Warehouses/enableResource', item.id);
				}
			});
		}
	}
};
</script>
