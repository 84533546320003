<template>
	<v-card height="100%">
		<v-card-text v-if="authenticated">
			<v-form @submit.prevent="actualizar" :disabled="loader">
				<v-row>
					<v-col cols="12" sm="6" md="3">
						<v-text-field
							outlined
							dense
							type="text"
							label="Nombre"
							v-model="user.nombre"
							:error-messages="errors.nombre"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="3">
						<v-text-field
							outlined
							dense
							type="text"
							label="Apellidos"
							v-model="user.apellidos"
							:error-messages="errors.apellidos"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="3">
						<v-text-field
							outlined
							dense
							type="email"
							label="Correo"
							v-model="user.email"
							:error-messages="errors.email"
						/>
					</v-col>
					<v-col cols="12" sm="6" md="3">
						<v-text-field
							outlined
							dense
							type="text"
							label="Teléfono"
							v-model="user.telefono"
							:error-messages="errors.telefono"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-checkbox
							v-model="user.pwd"
							label="Cambiar Contraseña"
							class="d-inline-flex"
						/>
					</v-col>
				</v-row>
				<v-row class="mt-3" v-if="user.pwd">
					<v-col cols="12" sm="6" xl="3">
						<v-text-field
							outlined
							dense
							type="password"
							label="Nueva Contraseña"
							v-model="user.newPassword"
							:error-messages="errors.newPassword"
						/>
					</v-col>
					<v-col cols="12" sm="6" xl="3">
						<v-text-field
							outlined
							dense
							type="password"
							label="Confirmar Nueva Contraseña"
							v-model="user.newPassword_confirmation"
							:error-messages="errors.newPassword"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn
							color="success"
							type="submit"
							:disabled="loader"
							class="mr-3"
						>
							Actualizar
						</v-btn>
						<v-btn color="error" @click="$router.push({ name: 'home' })">
							cancelar
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-text v-else>
			<v-card :loading="loader">
				<v-card-title primary-title>
					{{ usuario.name }} Ingresa tu contraseña para continuar
				</v-card-title>
				<v-form @submit.prevent="comprobar" :disabled="loader">
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="6" xl="3">
								<v-text-field
									dense
									outlined
									label="Contraseña"
									type="password"
									v-model="pwd"
									:error-messages="errors.pwd"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn color="success" type="submit" :disabled="loader">
							Comprobar
						</v-btn>
						<v-btn color="error" @click="$router.push({ name: 'home' })">
							cancelar
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'EditMe',
	data: () => ({
		user: {},
		pwd: undefined
	}),
	computed: {
		...mapState({
			authenticated: state => state.Auth.authenticated,
			loader: state => state.Auth.loader,
			errors: state => state.errors,
			usuario: state => state.Auth.user
		})
	},
	mounted() {
		this.$store.commit('Auth/setAuthenticated', false);
		this.$store.commit('setErrors');
	},
	methods: {
		comprobar() {
			this.$store.commit('setErrors');
			this.$store.commit('Auth/setLoader');
			window.axios
				.post('auth/sucursal/checkPass', { pwd: this.pwd })
				.then(resp => {
					this.user = resp.data;
					this.$store.commit('Auth/setAuthenticated', true);
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => this.$store.commit('Auth/setLoader', false));
		},
		actualizar() {
			this.$store.commit('setErrors');
			this.$store.commit('Auth/setLoader');
			window.axios
				.post('auth/sucursal/updateMe', this.user)
				.then(resp => {
					this.$store.dispatch('Auth/me');
					this.$swal.fire({
						icon: 'success',
						title: 'Datos Actualizados',
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000
					});
					this.$router.push({ name: 'home' });
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => this.$store.commit('Auth/setLoader', false));
		}
	}
};
</script>
