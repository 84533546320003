<template>
	<div>
		<v-row>
			<v-col cols="12" md="6" xl="3">
				<v-text-field
					dense
					outlined
					clearable
					label="Nombre"
					v-model="resource.name"
					:error-messages="errors.name"
					hide-details="auto"
				/>
			</v-col>
			<v-col cols="12" md="6" xl="3">
				<v-text-field
					dense
					outlined
					clearable
					label="Correo"
					v-model="resource.email"
					:error-messages="errors.email"
					hide-details="auto"
				/>
			</v-col>
			<v-col cols="12" md="6" xl="3">
				<v-text-field
					dense
					outlined
					clearable
					label="Teléfono"
					v-model="resource.phone"
					:error-messages="errors.phone"
					hide-details="auto"
				/>
			</v-col>
			<v-col cols="12" md="6" xl="">
				<v-text-field
					dense
					outlined
					clearable
					label="Contacto"
					v-model="resource.agent"
					:error-messages="errors.agent"
					hide-details="auto"
				/>
			</v-col>
			<v-col cols="12" md="6" xl="6">
				<v-text-field
					dense
					outlined
					clearable
					label="Localidad de Entrega (F.O.B en Shipper Sales Confirmation)"
					v-model="resource.location"
					:error-messages="errors.location"
					hide-details="auto"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Fields',
	mounted() {
		this.$store.commit('setErrors');
	},
	computed: {
		...mapState({
			errors: state => state.errors,
			loader: state => state.Warehouses.loader,
			resource: state => state.Warehouses.resource
		})
	}
};
</script>
