<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Archivos
		</v-expansion-panel-header>
		<v-expansion-panel-content v-if="nuevo">
			<Formulario @cancelar="cancelar" />
		</v-expansion-panel-content>
		<v-expansion-panel-content v-else>
			<v-row class="my-3">
				<v-col>
					<v-btn
						small
						color="success"
						:disabled="loader"
						v-if="userCan('sales-files-add')"
						@click="nuevo = true"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1"
			>
				<template v-slot:[`item.created_at`]="{ item }">
					{{ item.created_at | dateTime }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions :item="item" :loader="loader" @eliminar="eliminar" />
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Formulario from './UploadFileForm.vue';
export default {
	name: 'Files',
	components: { Formulario },
	data: () => ({
		headers: [
			{ text: 'Fecha', value: 'created_at' },
			{ text: 'Archivo', value: 'name' },
			{ text: 'Usuario', value: 'user' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		perPage: 15,
		search: undefined,
		nuevo: false
	}),
	computed: {
		...mapState({
			loader: state => state.Sales.loader,
			items: state => state.Sales.files
		})
	},
	methods: {
		cancelar() {
			this.nuevo = false;
		},
		eliminar(item) {
			this.$swal({
				title: `¿Eliminar el archivo ${item.name}?`,
				text: 'Esta acción no puede deshacerse',
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Sales/deleteFile', item.id);
				}
			});
		}
	}
};
</script>
