import router from '@/router';
const url = 'sucursal/customers';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: undefined,
	loader: false,
	paginado: { currentPage: 1 },
	addresses: [],
	address: {}
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload = {}) {
			state.resource = payload;
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setPaginado(state, payload) {
			let paginado = payload;
			if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
			state.paginado = paginado;
		},
		setAddresses(state, payload = []) {
			state.addresses = payload;
		},
		setAddress(state, payload = {}) {
			state.address = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection', []);
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setPaginado', resp.data.paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		nuevo({ commit }) {
			commit('setLoader');
			commit('setResource');
			commit('setLoader', false);
			router.push({ name: 'customers.create' }).catch(() => {});
		},
		async editar({ commit, dispatch }, id) {
			commit('setLoader');
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data.resource);
					commit('setAddresses', resp.data.addresses);
					router
						.push({ name: 'customers.edit', params: { id } })
						.catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async sendResource({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			let resource = state.resource;
			if (resource.id) {
				window.axios
					.put(`${url}/${resource.id}`, resource)
					.then(resp => {
						dispatch('message', resp.data.message, { root: true });
						dispatch('editar', resource.id);
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			} else {
				window.axios
					.post(url, resource)
					.then(resp => {
						dispatch('message', resp.data.message, { root: true });
						router.push({ name: 'customers' });
					})
					.catch(e => dispatch('errores', e, { root: true }))
					.finally(() => commit('setLoader', false));
			}
		},
		async disableResource({ commit, dispatch }, id) {
			commit('setLoader');
			await window.axios
				.delete(`${url}/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('getAll');
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async enableResource({ commit, dispatch }, id) {
			commit('setLoader');
			await window.axios
				.post(`${url}/restore/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('getAll');
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async disableAddress({ commit, dispatch, state }, id) {
			commit('setLoader');
			commit('setAddresses', []);
			window.axios
				.delete(`${url}/destroyAddress/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('editar', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async enableAddress({ commit, dispatch, state }, id) {
			commit('setLoader');
			commit('setAddresses', []);
			window.axios
				.post(`${url}/enableAddress/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('editar', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
