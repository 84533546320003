const url = 'auth/sucursal';
import router from '@/router';
const initialState = () => ({
	loader: undefined,
	user: undefined,
	abilities: undefined,
	menu: undefined,
	catalogos: [],
	notifications: undefined,
	authenticated: false,
	expedientes: [],
	inventario: [],
	presupuestos: []
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setUser(state, payload = undefined) {
			state.user = payload;
		},
		setAbilities(state, payload) {
			state.abilities = payload;
		},
		setMenu(state, payload) {
			state.menu = payload;
		},
		setCatalogos(state, payload = []) {
			state.catalogos = payload;
		},
		setNotifications(state, payload = undefined) {
			state.notifications = payload;
		},
		setAuthenticated(state, payload) {
			state.authenticated = payload;
		},
		setExpedientes(state, payload) {
			state.expedientes = payload;
		},
		setInventario(state, payload = []) {
			state.inventario = payload;
		},
		setPresupuestos(state, payload = []) {
			state.presupuestos = payload;
		}
	},
	actions: {
		async login({ commit, dispatch }, user) {
			commit('setLoader');
			window.axios
				.post(`${url}/login`, user)
				.then(resp => {
					let payload = resp.data;
					window.axios.defaults.headers.common.Authorization =
						'Bearer ' + payload.access_token; // Very important to have "Bearer "
					localStorage.setItem('token', payload.access_token);
					commit('setUser', payload.data.user);
					commit('setAbilities', payload.data.abilities);
					commit('setMenu', payload.data.menu);
					commit('setExpedientes', payload.data.expedientes);
					commit('setCatalogos', payload.data.catalogos);
					commit('setPresupuestos', payload.data.presupuestos);
					router.push('/inicio').catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async me({ commit, dispatch }) {
			commit('setLoader');
			window.axios
				.post(`${url}/me`)
				.then(resp => {
					commit('setUser', resp.data.user);
					commit('setAbilities', resp.data.abilities);
					commit('setMenu', resp.data.menu);
					commit('setExpedientes', resp.data.expedientes);
					commit('setCatalogos', resp.data.catalogos);
					commit('setPresupuestos', resp.data.presupuestos);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async logout({ commit, dispatch }) {
			window.axios
				.post(`${url}/logout`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					commit('setUser', undefined);
					window.axios.defaults.headers.common.Authorization = '';
					localStorage.removeItem('token');
					router.push('/login').catch(() => {});
					dispatch('reset', null, { root: true });
				})
				.catch(e => dispatch('errores', e, { root: true }));
		},
		async dashboard({ commit, dispatch }) {
			commit('setLoader');
			commit('setInventario');
			window.axios
				.get(`${url}/dashboard`)
				.then(resp => {
					if (resp.data.message) {
						dispatch('message', resp.data.message, { root: true });
					}
					commit('setInventario', resp.data.inventario);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
