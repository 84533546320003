<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="blue lighten-4">
			Disponibles
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-2">
				<v-col
					cols="12"
					md="6"
					xl="4"
					v-for="producto in productos"
					:key="producto"
				>
					<CardDisponibles :producto="producto" />
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import CardDisponibles from './CardDisponibles.vue';
export default {
	name: 'Disponibles',
	components: { CardDisponibles },
	computed: {
		...mapState({
			inventario: state => state.Auth.inventario
		}),
		disponibles() {
			return this.inventario.filter(x => x.disponibles > 0);
		},
		productos() {
			return [...new Set(this.disponibles.map(x => x.producto))].sort();
		}
	}
};
</script>
