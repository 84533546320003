<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Domicilios
		</v-expansion-panel-header>
		<v-expansion-panel-content v-if="!nuevo">
			<v-row class="my-3">
				<v-col>
					<v-btn
						small
						color="success"
						@click="newAddress"
						v-if="userCan('customer-add-address')"
						:disabled="loader"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				hide-default-footer
				:items-per-page="items.length"
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1"
				:item-class="rowClasses"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@editar="editar"
						@eliminar="eliminar"
						@restore="restore"
					/>
				</template>
			</v-data-table>
		</v-expansion-panel-content>
		<v-expansion-panel-content v-else>
			<AddressForm @cancelar="cancelar" />
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import AddressForm from './AddressForm.vue';
export default {
	name: 'Addresses',
	components: { AddressForm },
	computed: {
		...mapState({
			items: state => state.Customers.addresses,
			loader: state => state.Customers.loader
		})
	},
	data: () => ({
		headers: [
			{ text: 'Nombre', value: 'name' },
			{ text: 'Domicilio', value: 'address' },
			{ text: 'Horario', value: 'schedule' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		nuevo: false
	}),
	methods: {
		newAddress() {
			this.$store.commit('Customers/setAddress');
			this.nuevo = true;
		},
		cancelar() {
			this.nuevo = false;
			this.$store.commit('Customers/setLoader', false);
		},
		editar(item) {
			this.$store.commit('Customers/setAddress', Object.assign({}, item));
			this.nuevo = true;
		},
		eliminar(item) {
			let msg = `¿Deshabilitar el domicilio ${item.name}?`;
			this.$swal({
				title: msg,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Customers/disableAddress', item.id);
				}
			});
		},
		restore(item) {
			let msg = `Habilitar el domicilio ${item.name}?`;
			this.$swal({
				title: msg,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Customers/enableAddress', item.id);
				}
			});
		}
	}
};
</script>
