import store from '@/store';
const routes = [
	{
		path: '/Presupuestos',
		name: 'budgets',
		component: require('@/views/budgets/Index.vue').default,
		meta: {
			name: 'Control de Presupuestos',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Presupuestos/nuevo',
		name: 'budgets.create',
		component: require('@/views/budgets/Create.vue').default,
		meta: {
			name: 'Nuevo Presupuesto',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Presupuestos/detalles/:id',
		name: 'budgets.show',
		component: require('@/views/budgets/Details.vue').default,
		meta: {
			name: 'Detalles Presupuesto',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
