<template>
	<v-card class="mt-2" :loading="loader">
		<v-form @submit.prevent="enviar" :disabled="loader">
			<v-card-text>
				<v-row class="mt-2">
					<v-col>
						<v-file-input
							outlined
							dense
							label="Seleccione Archivo"
							small-chips
							clearable
							counter
							show-size
							truncate-length="50"
							v-model="file"
							accept="image/*,.pdf"
							:error-messages="errors.file"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" :disabled="loader" type="submit">subir</v-btn>
				<v-btn color="error" @click="$emit('cancelar')">cancelar</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Formulario',
	data: () => ({
		file: undefined
	}),
	computed: {
		...mapState({
			loader: state => state.Compras.loader,
			errors: state => state.errors,
			purchase: state => state.Compras.resource
		})
	},
	mounted() {
		this.$store.commit('setErrors');
	},
	methods: {
		enviar() {
			let formData = new FormData();
			formData.append('file', this.file);
			this.$store.commit('setErrors');
			this.$store.commit('Compras/setLoader');
			window.axios
				.post(`sucursal/purchases/uploadFile/${this.purchase.id}`, formData)
				.then(resp => {
					this.$store.dispatch('message', resp.data.message);
					this.$store.dispatch('Compras/details', this.purchase.id);
					this.$emit('cancelar');
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => this.$store.commit('Compras/setLoader', false));
		}
	}
};
</script>
