<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Productos
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-1">
				<v-col cols="12" md="6">
					Total de cajas: {{ cajas | numero }} <br />
					Importe: {{ importe | moneda }}
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						dense
						outlined
						clearable
						label="Buscar"
						v-model="search"
					/>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				hide-default-footer
				:items-per-page="items.length"
				:headers="headers"
				:items="items"
				class="elevation-1 mt-2"
				:search.sync="search"
			>
				<template v-slot:[`item.precio`]="{ item }">
					{{ item.precio | moneda }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Products',
	data: () => ({
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Pallet ID', value: 'pallet_id' },
			{ text: 'Cantidad', value: 'cantidad', align: 'end' },
			{ text: 'Precio U', value: 'precio', align: 'end' },
			{ text: 'Importe', value: 'importe', align: 'end' }
		],
		search: undefined
	}),
	computed: {
		...mapState({
			items: state => state.Sales.products
		}),
		cajas() {
			return window._.sumBy(this.items, 'cantidad');
		},
		importe() {
			return window._.sumBy(this.items, 'importe');
		}
	}
};
</script>
