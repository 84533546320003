<template>
	<v-card class="mt-3" :loading="loader">
		<v-card-title primary-title>
			{{ title }}
		</v-card-title>
		<v-form @submit.prevent="enviar" :disabled="loader">
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6" xl="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Nombre"
							v-model="resource.name"
							:error-messages="errors.name"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="6" xl="4">
						<v-textarea
							dense
							outlined
							label="Domicilio"
							v-model="resource.address"
							:error-messages="errors.address"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="4">
						<v-textarea
							dense
							outlined
							label="Horario"
							v-model="resource.schedule"
							:error-messages="errors.schedule"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="4">
						<v-textarea
							dense
							outlined
							label="Observaciones"
							v-model="resource.observations"
							:error-messages="errors.observations"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" type="submit" :disabled="loader">
					{{ btnText }}
				</v-btn>
				<v-btn color="error" @click="$emit('cancelar')"> cancelar </v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AddressForm',
	computed: {
		...mapState({
			loader: state => state.Customers.loader,
			resource: state => state.Customers.address,
			customer: state => state.Customers.resource,
			errors: state => state.errors
		}),
		title() {
			return this.resource.id ? 'Editar Domicilio' : 'Nuevo Domicilio';
		},
		btnText() {
			return this.resource.id ? 'actualizar' : 'guardar';
		}
	},
	mounted() {
		this.$store.commit('setErrors');
	},
	methods: {
		enviar() {
			this.$store.commit('setErrors');
			this.$store.commit('Customers/setLoader');
			if (this.resource.id) {
				window.axios
					.put(
						`sucursal/customers/updateAddress/${this.resource.id}`,
						this.resource
					)
					.then(resp => {
						this.$store.dispatch('message', resp.data.message);
						this.$store.dispatch('Customers/editar', this.customer.id);
						this.$emit('cancelar');
					})
					.catch(e => this.$store.dispatch('errores', e))
					.finally(() => this.$store.commit('Customers/setLoader', false));
			} else {
				window.axios
					.post(
						`sucursal/customers/addAddress/${this.customer.id}`,
						this.resource
					)
					.then(resp => {
						this.$store.dispatch('message', resp.data.message);
						this.$store.dispatch('Customers/editar', this.customer.id);
						this.$emit('cancelar');
					})
					.catch(e => this.$store.dispatch('errores', e))
					.finally(() => this.$store.commit('Customers/setLoader', false));
			}
		}
	}
};
</script>
