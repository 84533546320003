<template>
	<v-footer color="primary" app>
		<span class="white--text"> {{ appName }} &copy; 2021 </span>
	</v-footer>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Footer',
	computed: {
		...mapState({
			appName: state => state.appName
		})
	}
};
</script>
