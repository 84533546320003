<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			PU {{ pu.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form @submit.prevent="enviar" :disabled="editable">
				<v-row class="mt-2">
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							type="date"
							label="Fecha de entrega"
							v-model="pu.date"
							:error-messages="errors.date"
						/>
					</v-col>
					<v-col>
						<v-autocomplete
							dense
							outlined
							clearable
							label="Almacén de recogida"
							v-model="pu.warehouse_id"
							:items="warehouses"
							item-text="name"
							item-value="id"
							:error-messages="errors.warehouse_id"
						/>
					</v-col>
					<v-col>
						<v-autocomplete
							dense
							outlined
							clearable
							label="Domicilio Almacén"
							v-model="pu.warehouse_address_id"
							:items="addresses"
							item-text="name"
							item-value="id"
							:error-messages="errors.warehouse_address_id"
						/>
					</v-col>
					<v-col>
						<v-autocomplete
							dense
							outlined
							clearable
							label="Domicilio del Cliente"
							v-model="pu.destination_address_id"
							:items="customerAddresses"
							item-text="name"
							item-value="id"
							:error-messages="errors.destination_address_id"
						/>
					</v-col>
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							type="number"
							label="Termógrafos"
							id="id"
							v-model.number="pu.thermographs"
							:error-messages="errors.thermographs"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea
							outlined
							clearable
							v-model="pu.observations"
							label="Observaciones"
						/>
					</v-col>
				</v-row>
				<v-card-actions v-if="modificado">
					<v-btn color="success" type="submit" :disabled="loader">
						actualizar
					</v-btn>
					<v-btn color="error" @click="cancelar">cancelar</v-btn>
				</v-card-actions>
			</v-form>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Pu',
	data: () => ({
		original: undefined
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			pu: state => state.Budgets.pu,
			warehouses: state => state.Budgets.warehouses,
			customerAddresses: state => state.Budgets.customerAddresses,
			loader: state => state.Budgets.loader,
			resource: state => state.Budgets.resource
		}),
		modificado() {
			return JSON.stringify(this.original) !== JSON.stringify(this.pu);
		},
		warehouse() {
			return this.pu.warehouse_id
				? this.warehouses.find(x => x.id == this.pu.warehouse_id)
				: undefined;
		},
		addresses() {
			return this.warehouse ? this.warehouse.addresses : [];
		},
		warehouse_id() {
			return this.pu.warehouse_id;
		},
		editable() {
			if (!this.resource.editable) return true;
			return this.loader;
		}
	},
	mounted() {
		this.$store.commit('setErrors');
		this.original = Object.assign({}, this.pu);
	},
	methods: {
		enviar() {
			this.$store.dispatch('Budgets/updatePu');
		},
		cancelar() {
			this.$store.commit('setErrors');
			this.$store.commit('Budgets/setPu', this.original);
		}
	},
	watch: {
		pu: function (val) {
			this.original = Object.assign({}, val);
		},
		warehouse_id: function () {
			this.pu.warehouse_address_id = null;
		}
	}
};
</script>
