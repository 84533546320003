<template>
	<v-expansion-panel-content>
		<v-form @submit.prevent="enviar" :disabled="loader">
			<v-card :loading="loader" class="mt-2">
				<v-card-text>
					<v-row>
						<v-col cols="12" md="4">
							<v-text-field
								dense
								outlined
								clearable
								type="date"
								label="Fecha"
								v-model="charge.date"
								:error-messages="errors.date"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field
								dense
								outlined
								clearable
								label="Concepto"
								v-model="charge.concept"
								:error-messages="errors.concept"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field
								dense
								outlined
								type="number"
								step="0.01"
								label="Importe"
								v-model.number="charge.importe"
								:error-messages="errors.importe"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-textarea
								outlined
								label="Observaciones"
								v-model="charge.observations"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="success"
						type="submit"
						v-if="modificado"
						:disabled="loader"
					>
						{{ title }}
					</v-btn>
					<v-btn color="error" @click="$emit('cancelar')">cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-expansion-panel-content>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Formulario',
	data: () => ({
		original: undefined
	}),
	computed: {
		...mapState({
			loader: state => state.Sales.loader,
			charge: state => state.Sales.charge,
			sale: state => state.Sales.resource.id,
			errors: state => state.errors
		}),
		title() {
			return this.charge.id ? 'actualizar' : 'guardar';
		},
		modificado() {
			return JSON.stringify(this.original) !== JSON.stringify(this.charge);
		}
	},
	methods: {
		enviar() {
			this.$store.commit('Sales/setLoader');
			this.$store.commit('setErrors');
			if (this.charge.id) {
				window.axios
					.put(`sucursal/sales/updateCharge/${this.charge.id}`, this.charge)
					.then(resp => {
						this.$store.dispatch('message', resp.data.message);
						this.$store.dispatch('Sales/showResource', this.sale);
						this.$emit('cancelar');
					})
					.catch(e => this.$store.dispatch('errores', e))
					.finally(() => this.$store.commit('Sales/setLoader', false));
			} else {
				window.axios
					.post(`sucursal/sales/addCharge/${this.sale}`, this.charge)
					.then(resp => {
						this.$store.dispatch('message', resp.data.message);
						this.$store.dispatch('Sales/showResource', this.sale);
						this.$emit('cancelar');
					})
					.catch(e => this.$store.dispatch('errores', e))
					.finally(() => this.$store.commit('Sales/setLoader', false));
			}
		}
	},
	mounted() {
		this.original = Object.assign({}, this.charge);
	}
};
</script>
