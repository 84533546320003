<template>
	<v-app id="inspire">
		<v-navigation-drawer v-model="drawer" app>
			<div class="text-center py-1">
				<img :src="require('@/assets/california.png')" width="50%" alt="" />
			</div>
			<Menu />
		</v-navigation-drawer>
		<v-app-bar app color="primary" dark>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{ title }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				v-for="item in barra"
				:key="item.title"
				icon
				link
				:to="{ name: item.to }"
				class="d-none d-xl-flex"
				exact
			>
				<v-icon>{{ item.icon }}</v-icon>
			</v-btn>
		</v-app-bar>
		<v-main>
			<router-view />
			<Footer />
		</v-main>
	</v-app>
</template>

<script>
import { mapState } from 'vuex';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
export default {
	name: 'App',
	components: { Menu, Footer },
	data: () => ({
		drawer: null
	}),
	computed: {
		...mapState({
			appName: state => state.appName,
			menu: state => state.Auth.menu
		}),
		title() {
			return this.$route.meta.name ? this.$route.meta.name : this.appName;
		},
		barra() {
			if (!this.menu) return [];
			return this.menu.filter(x => x.class);
		}
	}
};
</script>
