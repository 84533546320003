import router from '@/router';
import axios from 'axios';
const url = 'sucursal/budgets';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: {},
	loader: false,
	paginado: { currentPage: 1 },
	customers: undefined,
	inventario: undefined,
	detail: undefined,
	products: undefined,
	pu: undefined,
	customerAddresses: undefined
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload = {}) {
			state.resource = payload;
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setPaginado(state, payload) {
			let paginado = payload;
			if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
			state.paginado = paginado;
		},
		setCustomers(state, payload = undefined) {
			state.customers = payload;
		},
		setInventario(state, payload = []) {
			state.inventario = payload;
		},
		setDetail(state, payload = {}) {
			state.detail = payload;
		},
		setProducts(state, payload = []) {
			state.products = payload;
		},
		setPu(state, payload = undefined) {
			state.pu = payload;
		},
		setWarehouses(state, payload = []) {
			state.warehouses = payload;
		},
		setCustomerAddresses(state, payload = []) {
			state.customerAddresses = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection', []);
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search,
						customer_id: state.paginado.customer_id,
						user_id: state.paginado.user_id,
						estado: state.paginado.estado
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setPaginado', resp.data.paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async createBudget({ commit, dispatch }) {
			commit('setLoader');
			commit('setResource');
			commit('setErrors', [], { root: true });
			window.axios
				.get(`${url}/nuevo`)
				.then(resp => {
					commit('setCustomers', resp.data.customers);
					router.push({ name: 'budgets.create' }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async storeResource({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			let budget = state.resource;
			await window.axios
				.post(url, budget)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('detailsResource', resp.data.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async detailsResource({ commit, dispatch }, id) {
			commit('setLoader');
			commit('setInventario');
			commit('setProducts');
			commit('setPu');
			await window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data.resource);
					commit('setCustomers', resp.data.customers);
					commit('setInventario', resp.data.inventario);
					commit('setProducts', resp.data.products);
					commit('setPu', resp.data.pu);
					commit('setWarehouses', resp.data.warehouses);
					commit('setCustomerAddresses', resp.data.customerAddresses);
					router.push({ name: 'budgets.show', params: { id } }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async updateResource({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			await window.axios
				.put(`${url}/${state.resource.id}`, state.resource)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('detailsResource', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async deleteDetail({ commit, dispatch, state }, id) {
			commit('setLoader');
			await window.axios
				.delete(`${url}/deleteDetail/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('detailsResource', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async deleteResource({ commit, dispatch }, id) {
			commit('setLoader');
			window.axios
				.delete(`${url}/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('getAll');
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async addPu({ commit, dispatch, state }) {
			commit('setLoader');
			window.axios
				.post(`${url}/addPu/${state.resource.id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('detailsResource', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async updatePu({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setErrors', [], { root: true });
			let pickUpOrder = state.pu;
			window.axios
				.post(`${url}/updatePu/${pickUpOrder.id}`, pickUpOrder)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('detailsResource', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async createSale({ commit, dispatch, state }) {
			commit('setLoader');
			let budget = state.resource;
			window.axios
				.post(`${url}/createSale/${budget.id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					let id = resp.data.id;
					router.push({ name: 'sales.show', params: { id } }).catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
