var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-3",attrs:{"loading":_vm.loader}},[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.enviar.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Producto","items":_vm.productos},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-col',[_c('v-btn',{staticClass:"boton",attrs:{"color":_vm.btnColor,"disabled":_vm.loader},on:{"click":function($event){_vm.selected = !_vm.selected}}},[_c('v-icon',[_vm._v("mdi-checkbox-multiple-marked-outline")])],1)],1)],1),_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('strong',[_vm._v("Cajas Seleccionadas "+_vm._s(_vm._f("numero")(_vm.cajas)))]),_c('strong',{staticClass:"ml-4"},[_vm._v(" Importe Total "+_vm._s(_vm._f("moneda")(_vm.importeTotal))+" ")])])],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","hide-default-footer":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.filtrados,"items-per-page":_vm.perPage},scopedSlots:_vm._u([{key:"item.precio_compra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio_compra))+" ")]}},{key:"item.apartar",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","dense":"","outlined":"","max":item.disponibles,"hide-details":"auto"},model:{value:(item.apartar),callback:function ($$v) {_vm.$set(item, "apartar", _vm._n($$v))},expression:"item.apartar"}})]}},{key:"item.precio_venta",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","step":"0.01","dense":"","outlined":"","hide-details":"auto"},model:{value:(item.precio_venta),callback:function ($$v) {_vm.$set(item, "precio_venta", _vm._n($$v))},expression:"item.precio_venta"}})]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_corte))+" ")]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":_vm.loader || !_vm.listos.length}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelar}},[_vm._v("cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }