import router from '@/router';
import Vue from 'vue';
const url = 'sucursal/pallets';
const initialState = () => ({
	loader: false,
	resultados: undefined
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setResultados(state, payload = undefined) {
			state.resultados = payload;
		},
		setSearch(state, payload) {
			state.search = payload;
		}
	},
	actions: {
		async search({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setResultados');
			commit('setErrors', [], { root: true });
			await window.axios
				.get(`${url}/search`, {
					params: {
						search: state.search
					}
				})
				.then(resp => {
					if (resp.data.message) {
						Vue.swal({
							icon: 'error',
							title: resp.data.message,
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 2500
						});
					}
					if (resp.data.resultados) {
						commit('setResultados', resp.data.resultados);
					}
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
