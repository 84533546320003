<template>
	<div>
		<div class="mover">
			Bienvenido {{ user.name }}
			<v-row v-if="!loader">
				<v-col cols="6" sm="3" xl="2" v-for="item in items" :key="item.name">
					<v-switch
						v-model="item.visible"
						:label="item.label"
						hide-details="auto"
					/>
				</v-col>
			</v-row>
		</div>
		<Inventario v-if="inventario && inventario.visible" />
		<ListaPrecios v-if="lista && lista.visible" />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Inventario from '../dashboard/PanelInventario.vue';
import ListaPrecios from '../dashboard/CardLista.vue';
export default {
	name: 'Dashboard',
	components: { Inventario, ListaPrecios },
	computed: {
		...mapState({
			user: state => state.Auth.user,
			loader: state => state.Auth.loader
		}),
		inventario() {
			return this.items.find(x => x.name == 'inventario');
		},
		lista() {
			return this.items.find(x => x.name == 'lista');
		}
	},
	data: () => ({
		algo: false,
		label: 'esta es la label',
		items: [
			{ name: 'inventario', visible: false, label: 'Inventario' },
			{ name: 'lista', visible: true, label: 'Lista Precios' }
		]
	})
};
</script>
<style scoped>
.mover {
	margin-left: 25px;
	padding: 15px;
}
</style>
