import router from '@/router';
const url = 'sucursal/purchases';
const initialState = () => ({
	headers: [],
	collection: [],
	resource: undefined,
	loader: false,
	paginado: { currentPage: 1 },
	details: undefined,
	files: []
});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setInitialState(state) {
			const newState = initialState();
			Object.keys(newState).forEach(key => {
				state[key] = newState[key];
			});
		},
		setHeaders(state, payload) {
			state.headers = payload;
		},
		setCollection(state, payload) {
			state.collection = payload;
		},
		setResource(state, payload) {
			state.resource = payload;
		},
		setLoader(state, payload = true) {
			state.loader = payload;
		},
		setPaginado(state, payload) {
			let paginado = payload;
			if (paginado.currentPage > paginado.lastPage) paginado.currentPage = 1;
			state.paginado = paginado;
		},
		setDetails(state, payload) {
			state.details = payload;
		},
		setFiles(state, payload = []) {
			state.files = payload;
		}
	},
	actions: {
		async getAll({ commit, dispatch, state }) {
			commit('setLoader');
			commit('setCollection', []);
			window.axios
				.get(url, {
					params: {
						page: state.paginado.currentPage,
						search: state.paginado.search
					}
				})
				.then(resp => {
					commit('setCollection', resp.data.collection);
					commit('setHeaders', resp.data.headers);
					commit('setPaginado', resp.data.paginado);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async details({ commit, dispatch }, id) {
			commit('setLoader');
			window.axios
				.get(`${url}/${id}`)
				.then(resp => {
					commit('setResource', resp.data.resource);
					commit('setDetails', resp.data.details);
					commit('setFiles', resp.data.files);
					router
						.push({ name: 'compras.details', params: { id } })
						.catch(() => {});
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		},
		async deleteFile({ commit, dispatch, state }, id) {
			commit('setLoader');
			await window.axios
				.post(`sucursal/deleteFile/${id}`)
				.then(resp => {
					dispatch('message', resp.data.message, { root: true });
					dispatch('details', state.resource.id);
				})
				.catch(e => dispatch('errores', e, { root: true }))
				.finally(() => commit('setLoader', false));
		}
	}
};
