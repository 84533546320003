<template>
	<v-card height="100%">
		<v-form
			@submit.prevent="$store.dispatch('Customers/sendResource')"
			:disabled="loader"
		>
			<v-card-text>
				<v-card :loading="loader">
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Nombre"
									v-model="resource.name"
									:error-messages="errors.name"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Correo"
									v-model="resource.email"
									:error-messages="errors.email"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Teléfono"
									v-model="resource.phone"
									:error-messages="errors.phone"
								/>
							</v-col>
							<v-col cols="12" md="6" xl="3">
								<v-text-field
									dense
									outlined
									clearable
									label="Contacto"
									v-model="resource.agent"
									:error-messages="errors.agent"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-card-text>

			<v-card-actions>
				<v-btn color="success" type="submit" :disabled="loader">
					{{ text }}
				</v-btn>
				<v-btn
					color="error"
					@click="$router.push({ name: 'customers' })"
					:disabled="loader"
				>
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Formulario',
	computed: {
		...mapState({
			resource: state => state.Customers.resource,
			loader: state => state.Customers.loader,
			errors: state => state.errors
		}),
		text() {
			if (!this.resource) return '';
			return this.resource.id ? 'actualizar' : 'guardar';
		}
	},
	mounted() {
		this.$store.commit('setErrors');
		if (!this.resource) {
			if (this.$route.params.id) {
				this.$store.dispatch('Customers/editar', this.$route.params.id);
			} else {
				this.$store.dispatch('Customers/nuevo');
			}
		}
	}
};
</script>
