import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
	modules,
	state: {
		errors: {},
		appName: 'FR Fresh Produce'
	},
	mutations: {
		setErrors(state, payload = {}) {
			state.errors = payload;
		}
	},
	actions: {
		reset({ commit }) {
			Object.keys(modules).forEach(moduleName => {
				commit(`${moduleName}/setInitialState`);
			});
		},
		errores({ commit, dispatch }, e) {
			const status = e.response.status;
			switch (status) {
				case 403:
					Vue.swal({
						icon: 'error',
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 2500,
						title: 'No cuentas con los permisos suficientes'
					});
					dispatch('Auth/me');
					router.push({ name: 'home' });
					break;

				case 401:
					Vue.swal({
						icon: 'error',
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 2500,
						title: 'La sessión Expiró'
					});
					window.axios.defaults.headers.common.Authorization = '';
					localStorage.removeItem('token');
					commit('setErrors', []);
					dispatch('reset');
					router.push('/login').catch(() => {});
					break;

				case 422:
					commit('setErrors', e.response.data.errors);
					if (!e.response.data.errors) {
						Vue.swal({
							icon: 'error',
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 2500,
							title: e.response.data.message
						});
					}
					break;

				case 404:
					Vue.swal({
						icon: 'error',
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 2500,
						title: 'Recurso NO Localizado'
					});
					router.push({ name: 'home' });
					break;

				case 406:
					Vue.swal({
						icon: 'error',
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 15000,
						title: e.response.data.message
					});
					window.axios.defaults.headers.common.Authorization = '';
					localStorage.removeItem('token');
					commit('setErrors', []);
					dispatch('reset');
					router.push('/login').catch(() => {});
					break;

				default:
					if (e.response.data.message)
						Vue.swal({
							icon: 'error',
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 2500,
							title: e.response.data.message
						});
					if (e.response.data.errors)
						commit('setErrors', e.response.data.errors);
					break;
			}
		},
		/* jshint -W137 */
		message({}, string) {
			Vue.swal({
				icon: 'success',
				title: string,
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 2500
			});
		}
	}
});
