<template>
	<v-card height="100%">
		<v-card-text>
			<v-card :loading="loader" v-if="resource">
				<v-form
					:disabled="loader"
					@submit.prevent="$store.dispatch('Warehouses/storeResource')"
				>
					<v-card-text> <Fields /> </v-card-text>
					<v-card-actions>
						<v-btn color="success" :disabled="loader" type="submit">
							guardar
						</v-btn>
						<v-btn color="error" @click="$router.push({ name: 'warehouses' })">
							cancelar
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import Fields from './Fields.vue';
export default {
	name: 'Formulario',
	components: { Fields },
	computed: {
		...mapState({
			loader: state => state.Warehouses.loader,
			resource: state => state.Warehouses.resource
		})
	},
	mounted() {
		if (!this.resource) {
			this.$store.dispatch('Warehouses/nuevo');
		}
	}
};
</script>
