<template>
	<v-card height="100%">
		<v-card-title>
			{{ producto }}
		</v-card-title>
		<v-card-text>
			<strong class="mb-1">Apartados: {{ total | numero }}</strong>
			<v-row>
				<v-col cols="4" v-for="item in items" :key="item.pallet_id">
					<v-card class="mb-2" color="orange lighten-4">
						<v-card-text>
							<strong>{{ item.pallet_id }}</strong
							><br />
							PU: {{ item.pu }} <br />
							Apartados: {{ item.apartados }}
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CardProducto',
	props: ['producto'],
	computed: {
		items() {
			return this.$store.state.Auth.inventario.filter(x => {
				return x.apartados > 0 && x.producto == this.producto;
			});
		},
		total() {
			return window._.sumBy(this.items, 'apartados');
		}
	}
};
</script>

<style lang="scss" escoped>
.v-card__title {
	padding: 5px 16px !important;
	font-size: 0.9rem;
}
</style>
