<template>
	<v-card height="100%">
		<v-card-text>
			<v-form
				@submit.prevent="$store.dispatch('Sales/getAll')"
				:disabled="loader"
			>
				<v-row>
					<v-col cols="12" md="6" xl="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar PO"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="3">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Cliente"
							v-model="paginado.customer_id"
							:items="paginado.customers"
							item-text="name"
							item-value="id"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="3">
						<v-btn color="info" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				mobile-breakpoint="0"
				class="elevation-1 mt-2"
				hide-default-footer
				:item-class="rowClasses"
				:headers="headers"
				:items="items"
				:loading="loader"
				:items-per-page="paginado.perPage"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions :item="item" :loader="loader" @details="details" />
				</template>
				<template v-slot:[`item.date`]="{ item }">
					{{ item.date | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
				<template v-slot:[`item.charges`]="{ item }">
					{{ item.charges | moneda }}
				</template>
				<template v-slot:[`item.total`]="{ item }">
					{{ item.total | moneda }}
				</template>
			</v-data-table>
			<Paginado :loader="loader" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	computed: {
		...mapState({
			loader: state => state.Sales.loader,
			headers: state => state.Sales.headers,
			items: state => state.Sales.collection,
			paginado: state => state.Sales.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		}
	},
	mounted() {
		this.$store.dispatch('Sales/getAll');
	},
	methods: {
		details(item) {
			this.$store.dispatch('Sales/showResource', item.id);
		}
	},
	watch: {
		actual: function () {
			this.$store.dispatch('Sales/getAll');
		}
	}
};
</script>
