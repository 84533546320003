/* eslint-disable prettier/prettier */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import routes from './routes';

const router = new VueRouter({
	routes,
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	const token = localStorage.token;
	if (to.matched.some(record => record.meta.requiresToken && !token)) {
		next('login');
	} else {
		window.scrollTo(0, 0);
		next();
	}
});
export default router;
