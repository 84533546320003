<template>
	<div>
		<v-btn
			icon
			color="primary"
			@click="$emit('abilities', item)"
			v-if="item.actions.abilities"
			:disabled="loader"
		>
			<v-icon>mdi-account-lock</v-icon>
		</v-btn>
		<v-btn
			icon
			color="primary"
			@click="$emit('details', item)"
			v-if="item.actions.details"
			:disabled="loader"
		>
			<v-icon>mdi-information</v-icon>
		</v-btn>
		<v-btn
			icon
			color="success"
			:href="item.url"
			target="_blank"
			v-if="item.actions.download"
			:disabled="loader"
		>
			<v-icon>mdi-cloud-download</v-icon>
		</v-btn>
		<v-btn
			icon
			color="success"
			@click="$emit('editar', item)"
			v-if="item.actions.edit"
			:disabled="loader"
		>
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-btn
			icon
			color="error"
			@click="$emit('eliminar', item)"
			v-if="item.actions.delete"
			:disabled="loader"
		>
			<v-icon>mdi-delete</v-icon>
		</v-btn>
		<v-btn
			icon
			color="success"
			@click="$emit('restore', item)"
			v-if="item.actions.restore"
			:disabled="loader"
		>
			<v-icon>mdi-delete-restore</v-icon>
		</v-btn>
		<v-btn
			icon
			color="error"
			:href="item.pdfPu"
			v-if="item.actions.pdf1"
			:disabled="loader"
			target="_blank"
		>
			<v-icon>mdi-file-pdf-box</v-icon>
		</v-btn>
		<v-btn
			icon
			color="error"
			:href="item.actions.pdf2"
			v-if="item.actions.pdf2"
			:disabled="loader"
			target="_blank"
		>
			<v-icon>mdi-file-pdf-box</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'Actions',
	props: ['item', 'loader']
};
</script>
