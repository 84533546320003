import store from '@/store';
const routes = [
	{
		path: '/Proveedores',
		name: 'providers',
		component: require('@/views/proveedores/Index.vue').default,
		meta: {
			name: 'Catálogo de Proveedores',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Proveedores/Nuevo',
		name: 'providers.create',
		component: require('@/views/proveedores/Form.vue').default,
		meta: {
			name: 'Nuevo Proveedor',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Proveedores/Editar/:id',
		name: 'providers.edit',
		component: require('@/views/proveedores/Form.vue').default,
		meta: {
			name: 'Editar Proveedor',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
