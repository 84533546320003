import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import './../sass/styles.scss';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: '#2a4977',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#00bcd4',
				success: '#4CAF50',
				warning: '#FFC107'
			}
		}
	},
	lang: {
		locales: { es },
		current: 'es'
	},
	icons: {
		iconfont: 'mdi' // default - only for display purposes
	}
});
