<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Busqueda
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form
				@submit.prevent="$store.dispatch('Pallets/search')"
				:disabled="loader"
			>
				<v-row class="mt-2">
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Pallet ID"
							id="id"
							v-model="search"
							:error-messages="errors.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="primary" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				mobile-breakpoint="0"
				class="elevation-1 mt-2"
				hide-default-footer
				:headers="headers"
				:items="items"
				:items-per-page="items.length"
				v-if="items"
			>
				<template v-slot:[`item.fecha_corte`]="{ item }">
					{{ item.fecha_corte | date }}
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Busqueda',
	computed: {
		...mapState({
			loader: state => state.Pallets.loader,
			items: state => state.Pallets.resultados,
			errors: state => state.errors
		}),
		search: {
			get: function () {
				return this.$store.state.Pallets.search;
			},
			set: function (val) {
				this.$store.commit('Pallets/setSearch', val);
			}
		}
	},
	data: () => ({
		headers: [
			{ text: 'Pallet ID', value: 'pallet_id' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'PU', value: 'pu' },
			{ text: 'Fecha de Corte', value: 'fecha_corte' },
			{ text: 'Vendidos', value: 'vendidos', align: 'end' },
			{ text: 'Apartados', value: 'apartados', align: 'end' },
			{ text: 'Disponibles', value: 'disponibles', align: 'end' }
		]
	})
};
</script>
