try {
	window._ = require('lodash');
	window.axios = require('axios');
	window.moment = require('moment');
} catch (e) {}
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
if (localStorage.token) {
	window.axios.defaults.headers.common.Authorization =
		'Bearer ' + localStorage.token;
}
//ponemos el titulo de la pagina de acuerdo al modo de node
document.title = process.env.VUE_APP_TITLE;
