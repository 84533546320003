<template>
	<v-card height="100%">
		<v-expansion-panels multiple v-if="resource">
			<Purchase />
			<Products />
			<Files />
		</v-expansion-panels>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'compras' })">
				regresar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import Purchase from './PanelPurchase.vue';
import Products from './PanelProducts.vue';
import Files from './PanelFiles.vue';
import { mapState } from 'vuex';
export default {
	name: 'Details',
	components: { Purchase, Products, Files },
	computed: {
		...mapState({
			resource: state => state.Compras.resource
		})
	},
	mounted() {
		if (!this.resource) {
			this.$store.dispatch('Compras/details', this.$route.params.id);
		}
	}
};
</script>
