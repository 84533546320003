<template>
	<div></div>
</template>

<script>
export default {
	mounted() {
		this.$store.dispatch('Auth/logout');
	}
};
</script>
