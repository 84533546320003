<template>
	<v-card class="mt-3">
		<v-card-text>
			<v-row>
				<v-col xl="4" md="6">
					<p class="mt-5 negrita">
						Total Apartados: {{ totalApartados | numero }} <br />
						Total Disponibles: {{ totalDisponibles | numero }}
					</p>
				</v-col>
				<v-col cols="12" xl="4" offset-xl="4" md="6" offset-md="6">
					<v-container>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Busqueda"
							single-line
							hide-details
						/>
					</v-container>
				</v-col>
			</v-row>
			<v-data-table
				dense
				:items-per-page="items.length"
				:headers="headers"
				:items="items"
				class="elevation-1"
				mobile-breakpoint="0"
				:search.sync="search"
				:loading="loader"
				hide-default-footer
			>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Inventario',
	data: () => ({
		headers: [
			{ text: 'Producto', value: 'producto' },
			{ text: 'Apartados', value: 'apartados', align: 'end' },
			{ text: 'Disponibles', value: 'disponibles', align: 'end' }
		],
		perPage: 10,
		search: undefined
	}),
	computed: {
		...mapState({
			inventario: state => state.Auth.inventario,
			loader: state => state.Auth.loader
		}),
		productos() {
			if (!this.inventario) return [];
			return [...new Set(this.inventario.map(x => x.producto))].sort();
		},
		items() {
			let array = [];
			if (this.productos.length) {
				this.productos.forEach(element => {
					let filtrados = this.inventario.filter(x => x.producto == element);
					let prod = {
						producto: element,
						apartados: window._.sumBy(filtrados, 'apartados'),
						disponibles: window._.sumBy(filtrados, 'disponibles')
					};
					array.push(prod);
				});
			}
			return array;
		},
		totalApartados() {
			return window._.sumBy(this.inventario, 'apartados');
		},
		totalDisponibles() {
			return window._.sumBy(this.inventario, 'disponibles');
		}
	},
	mounted() {
		this.$store.dispatch('Auth/dashboard');
	}
};
</script>
