<template>
	<v-card height="100%">
		<v-expansion-panels multiple v-if="resource">
			<Warehouse />
			<Addresses />
		</v-expansion-panels>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'warehouses' })">
				regresar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import Warehouse from './PanelWarehouse.vue';
import Addresses from './PanelAddresses.vue';
export default {
	name: 'Editar',
	components: { Warehouse, Addresses },
	computed: {
		...mapState({
			resource: state => state.Warehouses.resource
		})
	},
	mounted() {
		if (!this.resource) {
			this.$store.dispatch('Warehouses/editar', this.$route.params.id);
		}
	}
};
</script>
