import store from '@/store';
const routes = [
	{
		path: '/Compras',
		name: 'compras',
		component: require('@/views/compras/Index.vue').default,
		meta: {
			name: 'Control de Compras',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Compras/detalles/:id',
		name: 'compras.details',
		component: require('@/views/compras/Details.vue').default,
		meta: {
			name: 'Detalle de Compra',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
