<template>
	<v-list dense shaped>
		<template v-if="catalogos.length">
			<v-list-item-group color="primary">
				<v-list-group :value="false" prepend-icon="mdi-folder">
					<template v-slot:activator>
						<v-list-item-title>Catálogos</v-list-item-title>
					</template>
					<v-list-item
						link
						:to="{ name: item.to }"
						v-for="item in catalogos"
						:key="item.title"
						exact
					>
						<v-list-item-action>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list-item-group>
		</template>
		<template v-if="expedientes.length">
			<v-list-item-group color="primary">
				<v-list-group :value="false" prepend-icon="mdi-folder">
					<template v-slot:activator>
						<v-list-item-title>Expedientes</v-list-item-title>
					</template>
					<v-list-item
						link
						:to="{ name: item.to }"
						v-for="item in expedientes"
						:key="item.title"
						exact
					>
						<v-list-item-action>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list-item-group>
		</template>
		<template v-if="presupuestos.length">
			<v-list-item-group color="primary">
				<v-list-group :value="false" prepend-icon="mdi-folder">
					<template v-slot:activator>
						<v-list-item-title>Presupuestos</v-list-item-title>
					</template>
					<v-list-item
						link
						:to="{ name: item.to }"
						v-for="item in presupuestos"
						:key="item.title"
						exact
					>
						<v-list-item-action>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list-item-group>
		</template>
		<v-list-item
			link
			:to="{ name: item.to }"
			v-for="item in menu"
			:key="item.title"
			:class="item.class"
			exact
		>
			<v-list-item-action>
				<v-icon v-text="item.icon"></v-icon>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>
<script>
import { mapState } from 'vuex';
export default {
	computed: {
		...mapState({
			user: state => state.Auth.user,
			menu: state => state.Auth.menu,
			catalogos: state => state.Auth.catalogos,
			expedientes: state => state.Auth.expedientes,
			presupuestos: state => state.Auth.presupuestos
		})
	}
};
</script>
