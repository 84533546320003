import store from '@/store';
const routes = [
	{
		path: '/Clientes',
		name: 'customers',
		component: require('@/views/customers/Index.vue').default,
		meta: {
			name: 'Catálogo de Clientes',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Clientes/nuevo',
		name: 'customers.create',
		component: require('@/views/customers/Form.vue').default,
		meta: {
			name: 'Nuevo Cliente',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Clientes/editar/:id',
		name: 'customers.edit',
		component: require('@/views/customers/Edit.vue').default,
		meta: {
			name: 'Editando Cliente',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
