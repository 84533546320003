<template>
	<v-card class="mt-3">
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6">
					<v-text-field
						dense
						outlined
						clearable
						label="Buscar"
						v-model="paginado.search"
						hide-details="auto"
						append-icon="mdi-magnify"
					/>
				</v-col>
				<v-col cols="12" md="6">
					<v-btn
						color="success"
						:disabled="loader"
						@click="nuevo"
						class="alinear"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
					<v-btn color="error" class="alinear ml-2" @click="pdf">
						<v-icon>mdi-file-pdf-box</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				v-model="selected"
				show-select
				:headers="headers"
				:items="items"
				class="elevation-1 mt-4"
				:loading="loader"
				:items-per-page="items.length"
				hide-default-footer
				mobile-breakpoint="0"
				:search="paginado.search"
			>
				<template v-slot:[`item.price`]="{ item }">
					{{ item.price | moneda }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon color="success" @click="editar(item)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon color="error" @click="eliminar(item)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card-text>
		<Formulario
			v-if="dialog"
			:dialog="dialog"
			:resource="resource"
			@cancelar="cancelar"
			@actualizar="actualizar"
		/>
	</v-card>
</template>

<script>
import Formulario from './FormListPrice.vue';
import { Printd } from 'printd';
import fileDownload from 'js-file-download';
export default {
	name: 'ListaPrecios',
	components: { Formulario },
	data: () => ({
		headers: [
			{ text: 'Producto', value: 'name' },
			{ text: 'Precio', value: 'price', align: 'end', width: '20%' },
			{ text: 'Acciones', value: 'actions', align: 'end', width: '20%' }
		],
		url: 'sucursales/list-prices',
		items: [],
		paginado: { currentPage: 1, search: undefined },
		loader: false,
		resource: undefined,
		dialog: false,
		d: new Printd(),
		selected: [],
		urlDownload: undefined
	}),
	methods: {
		getAll() {
			this.loader = true;
			this.items = [];
			window.axios
				.get(this.url, {
					params: {
						page: this.paginado.currentPage,
						search: this.paginado.search
					}
				})
				.then(resp => {
					this.items = resp.data.collection;
					this.paginado = resp.data.paginado;
				})
				.catch(e => this.$store.dispatch('errores', e))
				.finally(() => (this.loader = false));
		},
		nuevo() {
			this.resource = {};
			this.dialog = true;
		},
		cancelar() {
			this.resource = undefined;
			this.dialog = false;
		},
		actualizar() {
			this.getAll();
			this.cancelar();
		},
		editar(item) {
			this.resource = Object.assign({}, item);
			this.dialog = true;
		},
		eliminar(item) {
			let msg = `¿ Eliminar ${item.name} de la lista de precios ?`;
			this.$swal({
				title: msg,
				text: 'Esta acción no puede deshacerse',
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					window.axios
						.delete(`${this.url}/${item.id}`)
						.then(resp => {
							this.getAll();
							this.$store.dispatch('message', resp.data.message);
						})
						.catch(e => this.$store.dispatch('errores', e));
				}
			});
		},
		print() {
			window.axios
				.post(`${this.url}/print`, { noIncluir: this.noMostrar })
				.then(resp => {
					const el = document.createElement('p');
					el.innerHTML = resp.data;
					this.d.print(el);
				})
				.catch(e => this.$store.dispatch('errores', e));
		},
		pdf() {
			window.axios
				.post('price-list-ca', {
					noIncluir: this.noMostrar
				})
				.then(resp => {
					this.urlDownload = resp.data.url;
					//window.open(resp.data.url, '_blank');
					this.downLoadPdf();
				})
				.catch(e => this.$store.dispatch('errores', e));
		},
		downLoadPdf() {
			if (this.urlDownload) {
				window.axios
					.get(this.urlDownload, {
						responseType: 'blob'
					})
					.then(response => {
						fileDownload(response.data, 'Price List.pdf');
					});
			}
		}
	},
	mounted() {
		this.getAll();
	},
	computed: {
		page() {
			return this.paginado.currentPage;
		},
		noMostrar() {
			return [...new Set(this.selected.map(x => x.id))];
		}
	},
	watch: {
		page() {
			this.getAll();
		}
	}
};
</script>
<style scoped>
.alinear {
	margin-top: 5px;
}
</style>
