<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Cargos Adicionales
		</v-expansion-panel-header>
		<Formulario v-if="nuevo" @cancelar="cancelar" />
		<v-expansion-panel-content v-else>
			<v-row class="my-3">
				<v-col>
					<v-btn
						small
						color="success"
						:disabled="loader"
						v-if="userCan('sales-charges')"
						@click="mostrar"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				dense
				mobile-breakpoint="0"
				:headers="headers"
				:items="items"
				class="elevation-1"
			>
				<template v-slot:[`item.date`]="{ item }">
					{{ item.date | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@editar="editar"
						@eliminar="eliminar"
					/>
				</template>
			</v-data-table>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Formulario from './AddChargeForm.vue';
export default {
	name: 'Charges',
	components: { Formulario },
	data: () => ({
		headers: [
			{ text: 'Fecha', value: 'date' },
			{ text: 'Concepto', value: 'concept' },
			{ text: 'Importe', value: 'importe', align: 'end' },
			{ text: 'Usuario', value: 'usuario' },
			{ text: 'Acciones', value: 'actions', align: 'end' }
		],
		nuevo: false
	}),
	computed: {
		...mapState({
			loader: state => state.Sales.loader,
			items: state => state.Sales.charges
		})
	},
	methods: {
		mostrar() {
			this.$store.commit('setErrors');
			this.$store.commit('Sales/setCharge');
			this.nuevo = true;
		},
		cancelar() {
			this.nuevo = false;
		},
		editar(item) {
			this.$store.commit('setErrors');
			this.$store.commit('Sales/setCharge', Object.assign({}, item));
			this.nuevo = true;
		},
		eliminar(item) {
			let msg = `¿Eliminar el cargo ${item.concept}?`,
				text = 'Esta acción no puede deshacerse';
			this.$swal({
				title: msg,
				text: text,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Sales/deleteCharge', item.id);
				}
			});
		}
	}
};
</script>
