<template>
	<v-card height="100%">
		<v-card-text>
			<v-form
				@submit.prevent="$store.dispatch('Budgets/getAll')"
				:disabled="loader"
			>
				<v-row>
					<v-col cols="12" md="6" xl="3">
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar PO"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="2">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Cliente"
							v-model="paginado.customer_id"
							:items="paginado.customers"
							type="search"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="2">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Usuario"
							v-model="paginado.user_id"
							:items="paginado.users"
							type="search"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="2">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Filtrar por Estatus"
							v-model="paginado.estado"
							:items="estatus"
							type="search"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="2">
						<v-btn color="info" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn
							color="success"
							class="ml-2"
							:disabled="loader"
							@click="$store.dispatch('Budgets/createBudget')"
							v-if="userCan('budgets-create')"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				mobile-breakpoint="0"
				class="elevation-1 mt-2"
				hide-default-footer
				:item-class="rowClasses"
				:headers="headers"
				:items="items"
				:loading="loader"
				:items-per-page="paginado.perPage"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@details="details"
						@eliminar="eliminar"
						@pdf1="pdf1"
					/>
				</template>
				<template v-slot:[`item.date`]="{ item }">
					{{ item.date | date }}
				</template>
				<template v-slot:[`item.importe`]="{ item }">
					{{ item.importe | moneda }}
				</template>
				<template v-slot:[`item.cajas`]="{ item }">
					{{ item.cajas | numero }}
				</template>
			</v-data-table>
			<Paginado :loader="loader" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	data: () => ({
		estatus: [
			{ text: 'Todos', value: null },
			{ text: 'Pendientes', value: 'si' },
			{ text: 'Cerrados', value: 'no' }
		]
	}),
	mounted() {
		this.$store.dispatch('Budgets/getAll');
	},
	computed: {
		...mapState({
			loader: state => state.Budgets.loader,
			headers: state => state.Budgets.headers,
			items: state => state.Budgets.collection,
			paginado: state => state.Budgets.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		search() {
			return this.paginado.search;
		},
		customer_id() {
			return this.paginado.customer_id;
		}
	},
	methods: {
		details(item) {
			this.$store.dispatch('Budgets/detailsResource', item.id);
		},
		eliminar(item) {
			this.$swal({
				title: `¿Eliminar el presupuesto ${item.folio}?`,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Budgets/deleteResource', item.id);
				}
			});
		},
		pdf1(item) {
			console.log(item);
		}
	},
	watch: {
		actual: function () {
			this.$store.dispatch('Budgets/getAll');
		},
		customer_id: function () {
			this.$store.dispatch('Budgets/getAll');
		}
	}
};
</script>
