<template>
	<v-card height="100%">
		<v-form
			@submit.prevent="$store.dispatch('Budgets/storeResource')"
			:disabled="loader"
		>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6" xl="2">
						<v-text-field
							dense
							outlined
							clearable
							label="Fecha"
							type="date"
							v-model="resource.date"
							:error-messages="errors.date"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="5">
						<v-autocomplete
							dense
							outlined
							clearable
							label="Cliente"
							:items="customers"
							item-value="id"
							item-text="name"
							v-model="resource.customer_id"
							:error-messages="errors.customer_id"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="6" xl="5">
						<v-combobox
							dense
							outlined
							clearable
							label="Comprador"
							:items="buyers"
							v-model="resource.buyer"
							:error-messages="errors.buyer"
						/>
					</v-col>
					<v-col cols="12" md="6" xl="2">
						<v-text-field
							dense
							outlined
							clearable
							label="PO (Orden de Compra)"
							v-model="resource.po"
							:error-messages="errors.po"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-textarea
							outlined
							label="Observaciones"
							v-model="resource.observations"
							:error-messages="errors.observations"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn color="success" type="submit" :disabled="loader">
					guardar
				</v-btn>
				<v-btn color="error" @click="$router.push({ name: 'budgets' })">
					cancelar
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Create',
	computed: {
		...mapState({
			customers: state => state.Budgets.customers,
			resource: state => state.Budgets.resource,
			loader: state => state.Budgets.loader,
			errors: state => state.errors
		}),
		customer() {
			if (!this.customers) return {};
			return this.customers.find(x => x.id == this.resource.customer_id);
		},
		addresses() {
			return this.customer ? this.customer.addresses : [];
		},
		buyers() {
			return this.customer ? this.customer.buyers : [];
		},
		today() {
			return new Date().toISOString().split('T')[0];
		}
	},
	mounted() {
		if (!this.customers) this.$store.dispatch('Budgets/createBudget');
	}
};
</script>
