<template>
	<v-card height="100%" v-if="resource">
		<v-expansion-panels multiple>
			<Datos />
			<Domicilios />
		</v-expansion-panels>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'customers' })">
				regresar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import Datos from './PanelDatos.vue';
import Domicilios from './PanelAddresses.vue';
import { mapState } from 'vuex';
export default {
	name: 'Editar',
	components: { Datos, Domicilios },
	computed: {
		...mapState({
			resource: state => state.Customers.resource
		})
	},
	mounted() {
		if (!this.resource) {
			this.$store.dispatch('Customers/editar', this.$route.params.id);
		}
	}
};
</script>
