<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos del Almacen
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form
				@submit.prevent="$store.dispatch('Warehouses/updateResource')"
				:disabled="loader"
			>
				<v-card :loading="loader" class="mt-3">
					<v-card-text>
						<Fields />
					</v-card-text>
					<v-card-actions v-if="modificado">
						<v-btn color="success" type="submit">actualizar</v-btn>
						<v-btn color="error" @click="cancelar">cancelar</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import Fields from './Fields.vue';
export default {
	name: 'Warehouse',
	components: { Fields },
	data: () => ({
		original: undefined
	}),
	computed: {
		...mapState({
			loader: state => state.Warehouses.loader,
			resource: state => state.Warehouses.resource
		}),
		modificado() {
			return JSON.stringify(this.original) !== JSON.stringify(this.resource);
		}
	},
	mounted() {
		if (this.resource) {
			this.original = Object.assign({}, this.resource);
		}
	},
	methods: {
		cancelar() {
			this.$store.commit('Warehouses/setResource', this.original);
			this.$store.commit('setErrors');
		}
	},
	watch: {
		resource: function (val) {
			this.original = Object.assign({}, val);
		}
	}
};
</script>
