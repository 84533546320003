<template>
	<v-card height="100%">
		<v-expansion-panels multiple v-if="resource.id">
			<Budget />
			<Products />
			<Pu v-if="resource.pu" />
		</v-expansion-panels>
		<v-card-actions class="mt-4">
			<v-btn color="primary" @click="$router.push({ name: 'budgets' })">
				regresar a presupuestos
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import Budget from './PanelBudget.vue';
import Products from './PanelProducto.vue';
import Pu from './PanelPu.vue';
export default {
	name: 'Details',
	components: { Budget, Products, Pu },
	computed: {
		...mapState({
			resource: state => state.Budgets.resource
		})
	},
	mounted() {
		if (!this.resource.id) {
			this.$store.dispatch('Budgets/detailsResource', this.$route.params.id);
		}
	}
};
</script>
