import store from '@/store';
import Auth from './auth';
import Compras from './compras';
import Pallets from './pallets';
import Providers from './providers';
import Customers from './customers';
import Warehouses from './warehouses';
import Budgets from './budgets';
import Sales from './sales';
const baseRoutes = [
	{ path: '/', redirect: '/inicio' },
	{
		path: '/pruebas',
		name: 'pruebas',
		component: require('@/components/Pruebas.vue').default,
		meta: {
			name: 'Componente para pruebas',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/inicio',
		name: 'home',
		component: require('@/views/Home.vue').default,
		meta: {
			name: 'Inicio',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
const routes = baseRoutes.concat(
	Auth,
	Compras,
	Pallets,
	Providers,
	Customers,
	Warehouses,
	Budgets,
	Sales
);
routes.push({ path: '*', redirect: '/inicio' });
export default routes;
