import store from '@/store';
const routes = [
	{
		path: '/Ventas',
		name: 'sales',
		component: require('@/views/sales/Index.vue').default,
		meta: {
			name: 'Control de Ventas',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Ventas/detalles/:id',
		name: 'sales.show',
		component: require('@/views/sales/Show.vue').default,
		meta: {
			name: 'Detalle Venta',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
