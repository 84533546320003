<template>
	<v-row v-if="!loader">
		<v-col class="mt-5">
			Registros Encontrados: {{ paginado.registros | numero }}
		</v-col>
		<v-col cols="12" md="8">
			<v-pagination
				class="mt-3"
				v-model="paginado.currentPage"
				:length="paginado.lastPage"
				total-visible="8"
			/>
		</v-col>
		<v-col></v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Paginado',
	props: ['paginado', 'loader']
};
</script>
