<template>
	<v-card height="100%">
		<v-card-text>
			<v-form
				@submit.prevent="$store.dispatch('Customers/getAll')"
				:disabled="loader"
			>
				<v-row class="mt-2">
					<v-col>
						<v-text-field
							dense
							outlined
							clearable
							label="Buscar"
							v-model="paginado.search"
						/>
					</v-col>
					<v-col>
						<v-btn color="info" type="submit" :disabled="loader">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
						<v-btn
							color="success"
							class="ml-2"
							v-if="userCan('customer-create')"
							:disabled="loader"
							@click="$store.dispatch('Customers/nuevo')"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-data-table
				dense
				:headers="headers"
				:items="items"
				class="elevation-1"
				:loading="loader"
				:items-per-page="paginado.perPage"
				hide-default-footer
				:item-class="rowClasses"
			>
				<template v-slot:[`item.phone`]="{ item }">
					{{ item.phone | phone }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<Actions
						:item="item"
						:loader="loader"
						@editar="editar"
						@eliminar="eliminar"
						@restore="restore"
					/>
				</template>
			</v-data-table>
			<Paginado :loader="loader" :paginado="paginado" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Index',
	mounted() {
		this.$store.dispatch('Customers/getAll');
	},
	computed: {
		...mapState({
			loader: state => state.Customers.loader,
			headers: state => state.Customers.headers,
			items: state => state.Customers.collection,
			paginado: state => state.Customers.paginado
		}),
		actual() {
			return this.paginado.currentPage;
		},
		search() {
			return this.paginado.search;
		}
	},
	methods: {
		editar(item) {
			this.$store.dispatch('Customers/editar', item.id);
		},
		eliminar(item) {
			let msg = `¿Deshabilitar al cliente ${item.name}?`,
				text = 'No se podrán hacer nuevas compras';
			this.$swal({
				title: msg,
				text: text,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Customers/disableResource', item.id);
				}
			});
		},
		restore(item) {
			this.$swal({
				title: `¿Habilitar al cliente ${item.name}?`,
				icon: 'warning',
				showCancelButton: true
			}).then(resp => {
				if (resp.isConfirmed) {
					this.$store.dispatch('Customers/enableResource', item.id);
				}
			});
		}
	},
	watch: {
		actual: function () {
			this.$store.dispatch('Customers/getAll');
		},
		search: function (val) {
			if (!val) this.$store.dispatch('Customers/getAll');
		}
	}
};
</script>
