/* eslint-disable prettier/prettier */
require('./bootstrap');
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
loadProgressBar();
import './sass/styles.scss';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const optSwa = {
	confirmButtonColor: '#4CAF50',
	cancelButtonColor: '#d33',
	confirmButtonText: 'Si',
	cancelButtonText: 'No'
};
Vue.use(VueSweetalert2, optSwa);

Vue.config.productionTip = false;

Vue.filter('numero', function (value) {
	if (isNaN(value)) value = parseFloat(value);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	if (isNaN(value)) return '';
	var numberString = formatter
		.formatToParts(value)
		.map(({ type, value }) => {
			switch (type) {
				case 'currency':
					return ` `;
				case 'fraction':
					return '';
				case 'decimal':
					return '';
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);
	return numberString;
});
Vue.filter('moneda', function (value) {
	if (!value) return '';
	if (isNaN(value)) value = parseFloat(value);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	if (isNaN(value)) return '';
	var numberString = formatter
		.formatToParts(value)
		.map(({ type, value }) => {
			switch (type) {
				case 'currency':
					return `${value} `;
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);
	return numberString;
});
Vue.filter('date', function (value) {
	if (value) {
		let texto = window.moment(value).locale('es-MX').format('DD/MMM/YYYY');
		texto = texto.replace('ene.', 'Ene');
		texto = texto.replace('feb.', 'Feb');
		texto = texto.replace('mar.', 'Mzo');
		texto = texto.replace('abr.', 'Abr');
		texto = texto.replace('may.', 'May');
		texto = texto.replace('jun.', 'Jun');
		texto = texto.replace('jul.', 'Jul');
		texto = texto.replace('ago.', 'Ago');
		texto = texto.replace('sep.', 'Sep');
		texto = texto.replace('oct.', 'Oct');
		texto = texto.replace('nov.', 'Nov');
		texto = texto.replace('dic.', 'Dic');
		return texto;
	}
	return '';
});
Vue.filter('dateTime', function (value) {
	if (value) {
		let texto = window
			.moment(value)
			.locale('es-MX')
			.format('DD/MMM/YYYY hh:mm A');
		texto = texto.replace('ene.', 'Ene');
		texto = texto.replace('feb.', 'Feb');
		texto = texto.replace('mar.', 'Mzo');
		texto = texto.replace('abr.', 'Abr');
		texto = texto.replace('may.', 'May');
		texto = texto.replace('jun.', 'Jun');
		texto = texto.replace('jul.', 'Jul');
		texto = texto.replace('ago.', 'Ago');
		texto = texto.replace('sep.', 'Sep');
		texto = texto.replace('oct.', 'Oct');
		texto = texto.replace('nov.', 'Nov');
		texto = texto.replace('dic.', 'Dic');
		return texto;
	}
	return '';
});
Vue.filter('phone', function (val) {
	if (!val) return '';
	let code = val.substr(0, 3),
		first = val.substr(3, 3),
		last = val.substr(6, 4);
	return `(${code}) ${first} - ${last}`;
});

Vue.component('Paginado', require('@/components/Paginado.vue').default);
Vue.component('Actions', require('@/components/Actions.vue').default);
Vue.mixin({
	methods: {
		rowClasses(item) {
			if (item.actions.restore || item.deleted_at) {
				return 'red lighten-5'; //can also return multiple classes e.g ["orange","disabled"]
			}
		},
		userCan(ability) {
			let abilities = store.state.Auth.abilities;
			if (!abilities) return false;
			return abilities.includes(ability) || abilities.includes('*');
		}
	}
});

new Vue({
	store,
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app');
