import store from '@/store';
const routes = [
	{
		path: '/Almacenes',
		name: 'warehouses',
		component: require('@/views/warehouses/Index.vue').default,
		meta: {
			name: 'Catálogo de Almacenes',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Almacenes/nuevo',
		name: 'warehouses.create',
		component: require('@/views/warehouses/Form.vue').default,
		meta: {
			name: 'Nuevo Almacén',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	},
	{
		path: '/Almacenes/editar/:id',
		name: 'warehouses.edit',
		component: require('@/views/warehouses/Edit.vue').default,
		meta: {
			name: 'Editando Almacén',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
