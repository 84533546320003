<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Compra PU: {{ resource.pu }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-2">
				<v-col cols="12" md="4">Fecha: {{ resource.fecha | date }}</v-col>
				<v-col cols="12" md="4">Proveedor: {{ resource.provider }}</v-col>
				<v-col cols="12" md="4">PU: {{ resource.pu }}</v-col>
				<v-col cols="12" md="4">PO: {{ resource.po }}</v-col>
				<v-col cols="12" md="4">Importe {{ resource.importe | moneda }}</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Purchase',
	computed: {
		...mapState({
			resource: state => state.Compras.resource
		})
	}
};
</script>
