<template>
	<v-card height="100%">
		<v-expansion-panels multiple v-if="resource">
			<Sale />
			<Products />
			<Charges />
			<Files />
		</v-expansion-panels>
		<v-card-actions>
			<v-btn color="primary" @click="$router.push({ name: 'sales' })">
				regresar a ventas
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import Sale from './PanelSale.vue';
import Products from './PanelProducts.vue';
import Charges from './PanelCharges.vue';
import Files from './PanelFiles.vue';
export default {
	name: 'Show',
	components: { Sale, Products, Charges, Files },
	computed: {
		...mapState({
			resource: state => state.Sales.resource
		})
	},
	mounted() {
		if (!this.resource) {
			this.$store.dispatch('Sales/showResource', this.$route.params.id);
		}
	}
};
</script>
