<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="green lighten-4">
			Datos de la Venta Folio: {{ resource.folio }}
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-1">
				<v-col cols="12" sm="4" xl="3">
					Fecha: {{ resource.date | date }}
				</v-col>
				<v-col cols="12" sm="4" xl="3"> PO: {{ resource.po }} </v-col>
				<v-col cols="12" sm="4" xl="3"> PU: {{ resource.pu }} </v-col>
				<v-col cols="12" sm="6" xl="3">
					Cliente: {{ resource.customer }}
				</v-col>
				<v-col cols="12" sm="6" xl="3"> Usuario: {{ resource.user }} </v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Sale',
	computed: {
		...mapState({
			resource: state => state.Sales.resource
		})
	}
};
</script>
