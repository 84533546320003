<template>
	<v-card height="100%">
		<v-expansion-panels multiple v-model="abiertos">
			<Busqueda />
			<Disponibles />
			<Apartados />
		</v-expansion-panels>
		<v-card-actions>
			<v-btn color="primary" @click="$store.dispatch('Auth/dashboard')">
				actualizar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import Busqueda from './PanelBusqueda.vue';
import Disponibles from './PanelDisponibles.vue';
import Apartados from './PanelApartados.vue';
export default {
	name: 'Index',
	components: {
		Apartados,
		Busqueda,
		Disponibles
	},
	data: () => ({
		abiertos: []
	}),
	mounted() {
		this.$store.dispatch('Auth/dashboard');
		this.$store.commit('setErrors');
	}
};
</script>
