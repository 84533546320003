import store from '@/store';
const routes = [
	{
		path: '/login',
		name: 'login',
		component: require('@/views/auth/Login.vue').default,
		meta: {
			name: 'Ingresa tus credenciales'
		},
		beforeEnter: (to, from, next) => {
			const token = localStorage.token;
			if (token) {
				next('home');
			} else next();
		}
	},
	{
		path: '/logout',
		name: 'logout',
		component: require('@/views/auth/Logout.vue').default,
		meta: {
			name: 'Saliendo del Sistema ...',
			requiresToken: true
		}
	},
	{
		path: '/editar-mis-datos',
		name: 'editMe',
		component: require('@/views/auth/EditMe.vue').default,
		meta: {
			name: 'Editar mis Datos',
			requiresToken: true
		},
		beforeEnter: (to, from, next) => {
			const user = store.state.Auth.user;
			if (!user) {
				store.dispatch('Auth/me');
			}
			next();
		}
	}
];
export default routes;
