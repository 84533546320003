<template>
	<v-dialog persistent v-model="dialog" max-width="800">
		<v-card>
			<v-card-title class="text-h5">
				{{ title }}
			</v-card-title>
			<v-form :disabled="loader" @submit.prevent="enviar">
				<v-card-text>
					<v-row>
						<v-col cols="12" md="8">
							<v-text-field
								dense
								outlined
								label="Nombre"
								v-model="resource.name"
								:error-messages="errors.name"
								hide-details="auto"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field
								dense
								outlined
								label="Precio"
								v-model.number="resource.price"
								:error-messages="errors.price"
								hide-details="auto"
								type="number"
								append-icon="mdi-currency-usd"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" type="submit" :disabled="loader">
						{{ btnTitle }}
					</v-btn>
					<v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
						cancelar
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	name: 'Formulario',
	props: ['dialog', 'resource'],
	data: () => ({
		url: 'sucursales/list-prices'
	}),
	computed: {
		...mapState({
			errors: state => state.errors,
			loader: state => state.Sales.loader
		}),
		title() {
			return this.resource.id ? 'Editar Producto' : 'Nuevo Producto';
		},
		btnTitle() {
			return this.resource.id ? 'actualizar' : 'guardar';
		}
	},
	methods: {
		...mapMutations('Sales', ['setLoader']),
		...mapActions(['message', 'errores']),
		...mapMutations(['setErrors']),
		enviar() {
			this.setLoader();
			this.setErrors();
			if (this.resource.id) {
				window.axios
					.put(`${this.url}/${this.resource.id}`, this.resource)
					.then(resp => {
						this.message(resp.data.message);
						this.$emit('actualizar');
					})
					.catch(e => this.errores(e))
					.finally(() => this.setLoader(false));
			} else {
				window.axios
					.post(this.url, this.resource)
					.then(resp => {
						this.message(resp.data.message);
						this.$emit('actualizar');
					})
					.catch(e => this.errores(e))
					.finally(() => this.setLoader(false));
			}
		}
	},
	mounted() {
		this.setErrors();
	}
};
</script>
