<template>
	<v-expansion-panel>
		<v-expansion-panel-header color="orange lighten-4">
			Apartados
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-row class="mt-2">
				<v-col
					cols="12"
					md="6"
					xl="4"
					v-for="producto in productos"
					:key="producto"
				>
					<CardApartados :producto="producto" />
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import CardApartados from './CardApartados.vue';
export default {
	name: 'Apartados',
	components: { CardApartados },
	computed: {
		...mapState({
			inventario: state => state.Auth.inventario
		}),
		apartados() {
			return this.inventario.filter(x => x.apartados > 0);
		},
		productos() {
			return [...new Set(this.apartados.map(x => x.producto))].sort();
		}
	}
};
</script>
